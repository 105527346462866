import { css } from '@emotion/core';

export const styWrapper = css`
  display: flex;
  .wedding-invitation {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    height: 950px;
  }
  .wish {
    width: 100%;
    margin: 30px 0;
    overflow: hidden;
    box-shadow: 0 0 10px rgb(202, 202, 204);
    background-color: white;
    border-radius: 2px;
    padding: 0 16px;
  }
  .ivitation-card {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-image: url(https://storage.googleapis.com/vinhnhatwedding/invitation/header.png), url(https://storage.googleapis.com/vinhnhatwedding/invitation/footer.png);
    background-repeat: no-repeat;
    background-position: top, bottom;
    background-size: contain;
    width: 100%;
    max-width: 900px; /* Adjust max-width as needed */
    overflow: hidden;
    box-shadow: 0 0 10px rgb(202, 202, 204);
    border-radius: 2px;
    padding: 0 16px;
  }
  .ticket {
    display: flex;
    #qrcode {
      justify-content: center;
      align-content: center;
      margin: 12px auto;
    }
  }
  .ivitation-card-content__title {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .ivitation-card-content {
    width: 100%;
    max-width: 700px; /* Adjust max-width as needed */
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    box-sizing: border-box;
    width: 550px;
    padding-top: 15px;
    padding-bottom: 35px;
  }
  .ivitation-card-content__title--dear {
    text-align: center;
    font-size: 25px;
    color: #272727;
    font-family: 'Cormorant Garamond';
    font-weight: 600;
    font-style: normal;
    margin-top: 13px;
  }
  .ivitation-card-content__title--name {
    font-size: 35px;
    color: #d2928a;
    font-family: 'Cormorant Garamond';
    font-weight: 600;
    font-style: normal;
    margin-bottom: 10px;
    text-transform: uppercase;
  }
  .ivitation-card-content__title--desc {
    width: 80%;
    text-align: center;
    font-family: 'Cormorant Garamond';
    font-weight: 600;
    font-style: normal;
    font-size: 15px;
    color: #272727;
    letter-spacing: 2px;
  }
  .ivitation-card-content__main--man {
    font-family: 'Angella White';
    font-weight: 400;
    font-style: normal;
    font-size: 60px;
    color: #bc6d81;
  }
  .ivitation-card-content__main--and {
    margin: -10px;
    font-family: 'Alex Brush', cursive;
    font-size: 40px;
    color: #bc6d81;
  }
  .ivitation-card-content__main--woman {
    font-family: 'Angella White';
    font-weight: 400;
    font-style: normal;
    font-size: 60px;
    color: #bc6d81;
  }
  .ivitation-card-content__main--restaurant {
    font-size: 25px;
    color: #d2928a;
    font-family: 'Cormorant Garamond';
    font-weight: 600;
    font-style: normal;
  }
  .ivitation-card-content__main--lobby {
    text-align: center;
    font-size: 20px;
    color: #272727;
    font-family: 'Cormorant Garamond';
    font-weight: 600;
    font-style: italic;
  }
  .ivitation-card-content__main--address {
    font-size: 20px;
    color: #272727;
    font-family: 'Cormorant Garamond';
    font-weight: 600;
    font-style: italic;
  }
  .ivitation-card-content__main--timeline {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .ivitation-card-content__main--timeline img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
  }
  .details {
    color: #828282;
    padding: 16px 0;
    border-top: 1px dashed #c3c3c3;
  }

  .tinfo {
    font-size: 10px;
    font-weight: 300;
    color: #555;
    font-family: 'Roboto', sans-serif;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin: 16px 0 0 0;
  }

  .tdata {
    font-size: 1em;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
    letter-spacing: 0.5px;
    margin: 0;
  }

  .additional {
    font-size: 10px;
  }

  .name {
    font-size: 1.3em;
    font-weight: 500;
  }

  .masinfo {
    display: block;
  }
  .left,
  .right {
    width: 49%;
    display: inline-table;
  }

  .nesp {
    letter-spacing: 0px;
  }

  .btn {
    margin-right: 4px;
    margin-bottom: 4px;
    font-family: 'Work Sans', Arial, sans-serif;
    font-size: 12px;
    font-weight: 400;
    border-radius: 30px;
    transition: 0.5s;
    padding: 8px 20px;
  }

  .primary {
    background: #f14e95;
    color: #fff;
    border: none;
  }
  @media screen and (min-width: 330px) and (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    .wedding-invitation {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding: 20px;
      box-sizing: border-box;
      height: 630px;
      width: 375px;
    }
    .ivitation-card-content__title {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 115%;
    }
    .ivitation-card-content {
      width: 100%;
      max-width: 700px;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      width: 550px;
      padding-top: 50px;
      padding-bottom: 62px;
    }
    .ivitation-card-content__title--dear {
      text-align: center;
      font-size: 20px;
      color: #272727;
      font-family: 'Cormorant Garamond';
      font-weight: 600;
      font-style: normal;
    }
    .ivitation-card-content__title--name {
      font-size: 25px;
      color: #d2928a;
      font-family: 'Cormorant Garamond';
      font-weight: 600;
      font-style: normal;
      margin-bottom: 10px;
      text-transform: uppercase;
    }
    .ivitation-card-content__title--desc {
      width: 80%;
      text-align: center;
      font-family: 'Cormorant Garamond';
      font-weight: 600;
      font-style: normal;
      font-size: 11px;
      color: #272727;
      letter-spacing: 2px;
    }
    .ivitation-card-content__main--man {
      font-family: 'Angella White';
      font-weight: 400;
      font-style: normal;
      font-size: 35px;
      color: #bc6d81;
    }
    .ivitation-card-content__main--and {
      margin: -10px;
      font-family: 'Alex Brush', cursive;
      font-size: 30px;
      color: #bc6d81;
    }
    .ivitation-card-content__main--woman {
      font-family: 'Angella White';
      font-weight: 400;
      font-style: normal;
      font-size: 35px;
      color: #bc6d81;
    }
    .ivitation-card-content__main--restaurant {
      font-size: 14px;
      color: #d2928a;
      font-family: 'Cormorant Garamond';
      font-weight: 600;
      font-style: normal;
    }
    .ivitation-card-content__main--lobby {
      text-align: center;
      font-size: 14px;
      color: #272727;
      font-family: 'Cormorant Garamond';
      font-weight: 600;
      font-style: italic;
    }
    .ivitation-card-content__main--address {
      font-size: 14px;
      color: #272727;
      font-family: 'Cormorant Garamond';
      font-weight: 600;
      font-style: italic;
    }
    .ivitation-card-content__main--timeline {
      margin-top: 30px;
      margin-bottom: 30px;
    }
    .ivitation-card-content__main--timeline img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 70%;
    }
  }
  /*Responsive screen 400px*/
  @media screen and (min-width: 400px) and (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    .wedding-invitation {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding: 20px;
      box-sizing: border-box;
      height: 680px;
      width: 430px;
    }
    .ivitation-card-content__title {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 115%;
    }
    .ivitation-card-content {
      width: 100%;
      max-width: 700px;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      width: 550px;
      padding-top: 50px;
      padding-bottom: 62px;
    }
    .ivitation-card-content__title--dear {
      text-align: center;
      font-size: 20px;
      color: #272727;
      font-family: 'Cormorant Garamond';
      font-weight: 600;
      font-style: normal;
    }
    .ivitation-card-content__title--name {
      font-size: 25px;
      color: #d2928a;
      font-family: 'Cormorant Garamond';
      font-weight: 600;
      font-style: normal;
      margin-bottom: 10px;
      text-transform: uppercase;
    }
    .ivitation-card-content__title--desc {
      width: 65%;
      text-align: center;
      font-family: 'Cormorant Garamond';
      font-weight: 600;
      font-style: normal;
      font-size: 11px;
      color: #272727;
      letter-spacing: 2px;
    }
    .ivitation-card-content__main--man {
      font-family: 'Angella White';
      font-weight: 400;
      font-style: normal;
      font-size: 35px;
      color: #bc6d81;
    }
    .ivitation-card-content__main--and {
      margin: -10px;
      font-family: 'Alex Brush', cursive;
      font-size: 30px;
      color: #bc6d81;
    }
    .ivitation-card-content__main--woman {
      font-family: 'Angella White';
      font-weight: 400;
      font-style: normal;
      font-size: 35px;
      color: #bc6d81;
    }
    .ivitation-card-content__main--restaurant {
      font-size: 14px;
      color: #d2928a;
      font-family: 'Cormorant Garamond';
      font-weight: 600;
      font-style: normal;
    }
    .ivitation-card-content__main--lobby {
      text-align: center;
      font-size: 14px;
      color: #272727;
      font-family: 'Cormorant Garamond';
      font-weight: 600;
      font-style: italic;
    }
    .ivitation-card-content__main--address {
      font-size: 14px;
      color: #272727;
      font-family: 'Cormorant Garamond';
      font-weight: 600;
      font-style: italic;
    }
    .ivitation-card-content__main--timeline {
      margin-top: 30px;
      margin-bottom: 30px;
    }
    .ivitation-card-content__main--timeline img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 70%;
    }
  }
  @media screen and (min-width: 2400px) and (max-width: 5000px) {
    .wedding-invitation {
      width: 1370px;
      height: 100vh;
      margin-right: -210px;
      margin-left: -210px;
    }
  }
  .ivitation-card-content__title--letter {
    width: 80%;
    text-align: center;
    font-family: 'Cormorant Garamond';
    font-weight: 600;
    font-style: normal;
    font-size: 17px;
    color: #272727;
    text-align: justify;
    margin-bottom: 5px;
    margin-top: 40px;
  }
  .form-confirm-attendance {
    display: flex;
    flex-direction: column;
    border: 1px solid #d2928a;
    padding: 40px;
    border-radius: 30px;
    background: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
  .attendance,
  .companions,
  .diet {
    height: 35px;
    width: 300px;
    margin-bottom: 20px;
    color: #272727;
    border-radius: 7px;
  }
  .btn-attendance {
    background-color: #bc6d81;
    color: white;
    padding: 10px 20px;
  }
  .btn-attendance:hover {
    background-color: #be7e76;
  }
  .label-attendance {
    text-align: center;
    font-size: 20px;
    color: #d2928a;
    margin-bottom: 20px;
  }
  .success-message {
    margin-top: 10px;
    text-align: center;
    color: #272727;
  }
  .success-message::before {
    display: inline;
    content: '✔ ';
    color: #22bb33;
  }
  @media screen and (min-width: 340px) and (max-width: 1200px){
    .ivitation-card-content__title--letter{
      font-size: 12px;
      margin-top: 45px;
      margin-left: 5px;
    }
    .form-confirm-attendance{
      padding: 20px;
    }
    .attendance,
    .companions,
    .diet{
      width: 240px;
      font-size: 14px;
      margin-bottom: 14px;
    }
    .label-attendance{
      font-size: 15px;
      margin-bottom: 15px;

    }
  }
  .btn-back{
    width: 10%;
    margin-top: 20px;
  }
  .button-back{
    background: #bc6d81;
  }
  .button-back:hover{
    background: #c27b8d;
  }
  @media screen and (min-width: 340px) and (max-width: 1200px){
    .btn-back{
      display: none;
    }
  }
}
`;

export const styTicket = css`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 18px);
  padding: 0 20px;

  .homepage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
  }

  .title {
    font-family: 'Cookie', cursive;
    font-weight: 600;
    letter-spacing: 4px;
    font-size: 45px;
    user-select: none;
    margin-top: 20px;
    text-align: center;
  }

  .sub-title-ticket {
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 1.7rem;
    user-select: none;
    margin-top: 20px;
    text-align: center;
  }

  .input-ticket {
    width: 85vw;
    min-width: auto;

    input {
      font-family: 'Work Sans', Courier, monospace;
      font-size: 18px;
      width: 100%;
      text-align: center;
      border-radius: 12px;
      padding: 10px 30px 10px 10px;
      background: transparent;
      border: 0.5px solid rgba(0, 0, 0, 0.3);
    }

    input[type='text']:disabled {
      background: #dddddd;
    }
  }
`;
