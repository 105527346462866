import React, { useEffect, useState } from 'react';
import { object } from 'prop-types';
import { useForm } from 'react-hook-form';

import { styWrapper } from './styles';
import { Link } from 'gatsby';

const attendanceOptions = [
  { value: '', label: 'Bạn sẽ tham dự chứ?' },
  { value: true, label: 'Sẽ tham dự' },
  { value: false, label: 'Không tham dự được' },
];

const companionsOptions = [
  { value: '', label: 'Bạn có người đi cùng không?' },
  { value: 1, label: 'Đi 1 người' },
  { value: 2, label: 'Đi 2 người' },
  { value: 3, label: 'Đi 3 người' },
  { value: 4, label: 'Đi 4 người' },
];

const dietOptions = [
  { value: '', label: 'Bạn có ăn chay không?' },
  { value: true, label: 'Có' },
  { value: false, label: 'Không' },
];

function TicketData({ guest, configData }) {
  const [attendance, setAttendance] = useState('');
  const [companions, setCompanions] = useState('');
  const [diet, setDiet] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [successMessage, setSuccessMessage] = useState('');

  const handleAttendanceChange = (event) => {
    const value = event.target.value;
    setAttendance(value);
    if (value === 'true') {
      setValue('companions', 1);
    } else {
      setValue('companions', '');
    }
  };

  const renderOptions = (options) => {
    return options.map((option) => (
      <option key={option.value} value={option.value}>
        {option.label}
      </option>
    ));
  };
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    if (attendance === '' || attendance === 'Chưa xác định được' || attendance === 'Không tham dự') {
      setValue('companions', '');
      setValue('diet', '');
    }
  }, [attendance, setValue]);
  useEffect(() => {
    if (attendance === '' && companions === '' && diet === '') {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  }, [attendance, companions, diet]);

  const onSubmit = (data) => {
    const convertedData = {
      is_attend: data.attendance === 'true',
      is_vegetarian: data.diet === 'true',
      total_guest: parseInt(data.companions, 10),
    };
    fetch(`https://api.vinhnhatwedding.com/api/v1/guest/rsvp`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        code: guest.code,
        confirm_result: convertedData,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setSuccessMessage('Xác nhận thành công');
        reset();
        setAttendance('');
        setCompanions('');
        setDiet('');
      });
  };
  const isEnglish = guest.code === 'ZLPJZ7';

  const attendanceOptionsLocalized = isEnglish
    ? [
        { value: '', label: 'Will you attend?' },
        { value: true, label: 'Will attend' },
        { value: false, label: 'Cannot attend' },
      ]
    : attendanceOptions;

  const companionsOptionsLocalized = isEnglish
    ? [
        { value: '', label: 'Will you bring anyone?' },
        { value: 1, label: 'Only me' },
        { value: 2, label: '2 people' },
        { value: 3, label: '3 people' },
        { value: 4, label: '4 people' },
      ]
    : companionsOptions;

  const dietOptionsLocalized = isEnglish
    ? [
        { value: '', label: 'Are you vegetarian?' },
        { value: true, label: 'Yes' },
        { value: false, label: 'No' },
      ]
    : dietOptions;
  return (
    <div css={styWrapper}>
      <div className="wedding-invitation">
        <div class="ivitation-card">
          <div className="ivitation-card-content">
            <div className="ivitation-card-content__title">
              <div className="ivitation-card-content__title--dear">DEAR</div>
              <div className="ivitation-card-content__title--name">{guest.eng_name}</div>
              <div className="ivitation-card-content__title--desc">
                YOU ARE INVITED TO BE A GUEST AT OUR WEDDING CEREMONY
              </div>
              <div className="ivitation-card-content__main--man">Vinh Huynh</div>
              <div className="ivitation-card-content__main--and">&</div>
              <div className="ivitation-card-content__main--woman">Nhat Nguyen</div>
              <div className="ivitation-card-content__main--timeline">
                <img src="https://i.imgur.com/I1x4J5z.png" alt="Image"></img>
              </div>
              <div className="ivitation-card-content__main--restaurant">Maison De Charme</div>
              <div className="ivitation-card-content__main--lobby">Daisy Hall</div>
              <div className="ivitation-card-content__main--address">
                Tran Xuan Soan, Tan Hung, District 7, Ho Chi Minh City
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="wedding-invitation">
        <div class="ivitation-card">
          <div className="ivitation-card-content">
            <div className="ivitation-card-content__title">
              <div className="ivitation-card-content__title--letter">
                {guest.code === 'ZLPJZ7' ? (
                  <div className="invitation-card-content__title--letter">
                    Dear
                    <b>
                      {' '}
                      {guest.prefix_name} {guest.short_name}
                    </b>
                    <br />
                    First of all, we would like to express our sincere gratitude to you for always showing us love and
                    wishing us well.
                    <br />
                    Our wedding day is a significant event and a meaningful milestone marking the beginning of a new
                    journey. Your presence is very important and will make our celebration more meaningful than ever.
                    <br />
                    We very much look forward to seeing you at our wedding ceremony ♥️
                    <br />
                    Best regards,
                    <br />
                    <b>Anh Vinh & Minh Nhat </b>
                  </div>
                ) : (
                  <div className="invitation-card-content__title--letter">
                    <b>
                      {guest.prefix_name} {guest.short_name}{' '}
                    </b>
                    thương mến, <br />
                    Trước hết, chúng mình/em muốn gửi lời cảm ơn chân thành nhất đến {guest.prefix_name} vì đã luôn yêu
                    thương, quan tâm và chúc phúc cho dâu rể.
                    <br />
                    Ngày cưới của chúng mình/em không chỉ là một sự kiện trọng đại, mà còn là cột mốc ý nghĩa đánh dấu
                    khởi đầu cho một hành trình mới. Sự hiện diện của {guest.prefix_name} là điều vô cùng quan trọng và
                    giúp chúng mình/em có một lễ cưới trọn vẹn và ý nghĩa hơn bao giờ hết.
                    <br />
                    Rất mong được gặp {guest.prefix_name} trong tiệc Báo hỷ của chúng mình/em nhe ♥️ <br />
                    <b>Anh Vinh & Minh Nhật </b>
                  </div>
                )}
              </div>
              {/* <form className="form" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-confirm-attendance">
                  <label className="label-attendance">{isEnglish ? 'CONFIRM ATTENDANCE' : 'XÁC NHẬN THAM DỰ'}</label>
                  <select className="attendance" {...register('attendance')} onChange={handleAttendanceChange}>
                    {renderOptions(attendanceOptionsLocalized)}
                  </select>
                  <select className="companions" {...register('companions')} disabled={attendance !== 'true'}>
                    {renderOptions(companionsOptionsLocalized)}
                  </select>
                  <select className="diet" {...register('diet')} disabled={attendance !== 'true'}>
                    {renderOptions(dietOptionsLocalized)}
                  </select>
                  <button className="btn btn-block btn-attendance" disabled={isButtonDisabled}>
                    {isEnglish ? 'Confirm' : 'Xác nhận'}
                  </button>
                  {successMessage &&
                    <div className="success-message">{isEnglish ? 'Confirmed successfully' : successMessage}</div>}
                </div>
              </form> */}
            </div>
          </div>
        </div>
      </div>

      <div className="btn-back">
        <Link to={`/`}>
          <button className="btn primary button-back">
            {isEnglish ? 'Back to main page' : 'Quay lại trang chính'}
          </button>
        </Link>
      </div>
    </div>
  );
}

TicketData.propTypes = {
  guest: object,
  configData: object,
};

TicketData.defaultProps = {
  guest: {},
  configData: {},
};

export default TicketData;
