import React, { useState, useEffect, useRef, useCallback } from 'react';
import MainLayout from '@components/Layout';
import Ticket from '@components/Ticket';
// import FormPopup from '../components/FormPopup';

import getQueryValue from '@helpers/getQueryValue';
import useGuestData from '@/hooks/useGuestData';
import useConfigData from '@/hooks/useConfigData';
import { styTicket } from '@components/Ticket/styles';

function ETicket({ location }) {
  const codeEticket = getQueryValue(location, 'code') || '';
  const nameEticket = getQueryValue(location, 'name') || '';
  const alreadyChecked = useRef(false);

  const [value, setValue] = useState('');
  const [selectedGuest, setSelectedGuest] = useState({});
  const [isShowTicket, setIsShowTicket] = useState(false);
  const { data, loading } = useGuestData(codeEticket);
  const { data: configData } = useConfigData();

  const handleCheckTicket = useCallback(
    (tempValue) => {
      const guestCode = (tempValue || value).toUpperCase();
      if (data) {
        setSelectedGuest(data);
        setIsShowTicket(true);
        return;
      }
      alert(`Không tìm thấy code ${guestCode}`);
      window.location.href = '/';
    },
    [data, nameEticket, value],
  );
  /**
   * Effect to autu-check code ticket
   */
  useEffect(() => {
    if (codeEticket !== '' && data && Object.keys(data).length > 0 && !alreadyChecked.current) {
      alreadyChecked.current = true;
      setValue(codeEticket);
      setTimeout(() => {
        handleCheckTicket(codeEticket);
      }, 1000);
    }
  }, [codeEticket, data, handleCheckTicket, loading]);

  return (
    <MainLayout>
      <div css={styTicket}>
        <div className="homepage">
          {isShowTicket ? (
            <Ticket guest={selectedGuest} configData={configData} />
          ) : (
            <h3 style={{ fontSize: '1.7rem' }}>Chuyển hướng tới trang thiệp mời</h3>
          )}
        </div>
      </div>
      {/* <FormPopup /> */}
    </MainLayout>
  );
}

export default ETicket;
